import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import ptLocale from '@fullcalendar/core/locales/pt';
import axios from "axios";
import { Button, Loader } from "rsuite";
import config from '../configurations/appsettings.json';


function EscalaMensal() {
    const [events, setEvents] = useState([]);
    const [date, setDate] = useState(getDateNow())
    const [isLoading, setIsLoading] = React.useState(false);

    useEffect(() => {
        if (localStorage.getItem('token')) {
            axios({
                method: "get",
                url: `${config.API_URL}/Workshift/SearchMonth/` + date,
                headers: {
                    'Authorization': `Basic ${btoa(localStorage.getItem('token'))}`// Aqui você define o tipo de autenticação e o token
                }
            }).then(function (response) {
                setEvents(response.data);
            }).catch((e) => {
                console.error(e);
                if (e?.response?.status === 401) {
                    localStorage.setItem('token', '');
                }
            }).finally(() => {
                setIsLoading(false);
            });
        }
    }, [date])

    function getDateNow() {
        const agora = new Date();
        const dataFormatadaPtBr = agora.toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' });

        // Extrair ano, mês e dia da data formatada
        const partesData = dataFormatadaPtBr.split(' ')[0].split('/');
        const ano = partesData[2].replace(',', '');
        const mes = partesData[1].padStart(2, '0'); // Garante que o mês tenha dois dígitos
        const dia = partesData[0].padStart(2, '0'); // Garante que o dia tenha dois dígitos

        const dataFormatada = `${ano}-${mes}-${dia}`;
        return dataFormatada;
    }

    const handleDatesSet = (dateInfo) => {
        const newMonth = dateInfo.start;
        setDate(newMonth.toISOString().slice(0, 10));
    };

    const handleExportExcel = () => {

    }

    return (
        <>
            {isLoading &&
                <Loader size="lg" speed="fast" center content="Carregando dados..." vertical />
            }

            <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                locale={ptLocale}
                headerToolbar={{
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridDay,listWeek'
                }}
                showNonCurrentDates={false}
                events={events}
                initialDate={date}
                datesSet={handleDatesSet}
                customToolbar={<Button onClick={handleExportExcel} />}
                dayMaxEventRows={true}
            />
        </>
    );
}

export default EscalaMensal;