import { useState } from "react";
import { Button, ButtonToolbar, Col, FlexboxGrid, Form, Modal, Placeholder } from "rsuite";

function ModalToken(props) {
    const [formFields, setFormFields] = useState({ token: '' });
    const [formValue] = useState();

    const saveToken = () => {
        props.onChange(formFields.token)
    }

    return <>
        <Modal open={true} >
            <Modal.Header>
                <Modal.Title>Conrfime a senha</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form formValue={formFields} onChange={(formValue) => { setFormFields(formValue) }} >
                    <FlexboxGrid justify="center" >
                        <FlexboxGrid.Item
                            style={{
                                padding: "10px"
                            }}
                            as={Col} colspan={24} sm={24} lg={24}
                        >
                            <Form.Group controlId="token">
                                <Form.ControlLabel>Informe a senha de acesso:</Form.ControlLabel>
                                <Form.Control
                                    name="token"
                                    placeholder="Informe a senha de acesso:"
                                    type="text"
                                />
                                <Form.HelpText>A senha de acesso é obrigatória</Form.HelpText>
                            </Form.Group>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={saveToken} appearance="primary">
                    Ok
                </Button>
            </Modal.Footer>
        </Modal>
    </>;
}

export default ModalToken;