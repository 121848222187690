import { Container, Content, Footer, Header, Nav, Navbar } from "rsuite";
import React, { useState } from "react";
import ModalToken from "./components/token/modal_token";
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import EscalaMensal from "./pages/escala_mensal";
import EscalaDiaria from "./pages/escala_diaria";
import SmartenfLogo from "./assets/logo.png";

function App() {
  const [token, setToken] = useState(localStorage.getItem('token'));

  return (
    <>
      <Router>
        <Container >
          {!token &&
            <ModalToken onChange={(t) => { setToken(t); localStorage.setItem('token', t); }} />
          }
          <Header>
            <Navbar style={{ backgroundColor: '#0a4058', color: 'white' }}>
              <Nav>
                <Nav.Item href="/">
                  <img src={SmartenfLogo} height={20} alt="Logomarca da empresa desenvolvedora" />
                </Nav.Item>
                <Nav.Item href="/">Escala por dia
                </Nav.Item>
                <Nav.Item href="/EscalaMensal">Escala Mensal
                </Nav.Item>
              </Nav>
            </Navbar>
          </Header>
          <Content>
            <Routes>
              <Route path="/" element={<EscalaDiaria />} />
              <Route path="/EscalaMensal" element={<EscalaMensal />} />
            </Routes>
          </Content>
          <Footer style={{ textAlign: 'center', position: 'fixed', bottom: 0, width: '100%', fontWeight: 'bold', fontSize: 12, color: 'gray' }}>
            <Container>
              Desenvolvido por SmartEnf®
            </Container>
          </Footer>
        </Container >
      </Router>
    </>
  );
}

export default App;
