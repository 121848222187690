import { Button, ButtonToolbar, Col, FlexboxGrid, Form, Loader, Panel, useToaster, Notification } from "rsuite";
import TabelaEscala from '../components/tabela_escala/tabela_escala';
import React, { useEffect, useState } from "react";
import axios from "axios";
import config from '../configurations/appsettings.json';

function EscalaDiaria() {
    const [escala, setEscala] = React.useState();
    const [msgAlert, setMsgAlert] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);
    const toaster = useToaster();
    const [formFields, setFormFields] = useState({ data_escala: getDateNow(), enfermeiro: localStorage.getItem('enfermeiro') || '' });
    const [salvarEscala, setSalvarEscala] = useState([]);

    function onSubmit(formValue, event) {
        search(formValue);
    }

    function getDateNow() {
        const agora = new Date();
        const dataFormatadaPtBr = agora.toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' });

        // Extrair ano, mês e dia da data formatada
        const partesData = dataFormatadaPtBr.split(' ')[0].split('/');
        const ano = partesData[2].replace(',', '');
        const mes = partesData[1].padStart(2, '0'); // Garante que o mês tenha dois dígitos
        const dia = partesData[0].padStart(2, '0'); // Garante que o dia tenha dois dígitos

        const dataFormatada = `${ano}-${mes}-${dia}`;
        return dataFormatada;
    }

    function search(formValue) {
        if (formValue.data_escala) {
            setIsLoading(true);
            axios({
                method: "get",
                url: `${config.API_URL}/Workshift/Search/` + formValue.data_escala,
                headers: {
                    'Authorization': `Basic ${btoa(localStorage.getItem('token'))}`// Aqui você define o tipo de autenticação e o token
                }
            }).then(function (response) {
                setEscala(response.data);
            }).catch((e) => {
                console.error(e);
                if (e?.response?.status === 401) {
                    setMsgAlert("Permissão negada, favor informar a senha correta!");
                    localStorage.setItem('token', '');
                } else {
                    setMsgAlert("Ocorreu um erro inesperado. Favor contate o administrador do sistema!")
                }
            }).finally(() => {
                setIsLoading(false);
            });
        } else {
            setMsgAlert('Preencha a data da escala');
        }
    }

    function save() {
        if (formFields.data_escala && formFields.enfermeiro && salvarEscala.length > 0) {
            setIsLoading(true);
            //{"name":"Rodolfo","date":"2024-06-22","shift_id":1}
            let saveData = salvarEscala.map((e) => { return { name: formFields.enfermeiro, date: formFields.data_escala, shift_id: Number(e.shift_id.replace('shift_', '')) } });
            axios({
                method: "post",
                url: `${config.API_URL}/Workshift/Save/`,
                data: saveData,
                headers: {
                    'Authorization': `Basic ${btoa(localStorage.getItem('token'))}`// Aqui você define o tipo de autenticação e o token
                }
            }).then(function (response) {
                search(formFields);
                const message = (
                    <Notification type={'success'} header={`Sucesso!`} closable>
                        <p>{response.data}</p>
                    </Notification>
                );
                toaster.push(message, { placement: 'topCenter' })
            }).catch((e) => {
                console.error(e);
                if (e?.response?.status === 401) {
                    setMsgAlert("Permissão negada, favor informar a senha correta!");
                    localStorage.setItem('token', '');
                    window.location.reload();
                } else {
                    setMsgAlert("Ocorreu um erro inesperado. Favor contate o administrador do sistema!")
                }
            }).finally(() => {
                setIsLoading(false);
            });
        } else if (!formFields.enfermeiro) {
            setMsgAlert('Preencha o nome do(a) enfermeiro(a)');
        } else if (!formFields.data_escala) {
            setMsgAlert('Preencha a data da escala');
        } else if (salvarEscala.length <= 0) {
            setMsgAlert('Selecione os turnos da escala');
        }
    }

    useEffect(() => {
        if (msgAlert) {
            const message = (
                <Notification type={'warning'} header={`Atenção`} closable>
                    <p>{msgAlert}</p>
                    <hr />
                    <ButtonToolbar>
                        <Button appearance="primary" onClick={() => { toaster.clear() }}>Ok</Button>
                    </ButtonToolbar>
                </Notification>
            );

            toaster.push(message);
            setMsgAlert('');
        }
    }, [msgAlert, toaster]);

    useEffect(() => {
        setSalvarEscala([]);
    }, [escala])

    const escalaChange = (data) => {
        let escalaAtual = Object.assign([], salvarEscala);


        const existingIndexShift = escalaAtual.findIndex(
            (shift) => shift.shift_id === data.shift_id && shift.vacancy_id !== data.vacancy_id
        );

        if (existingIndexShift !== -1) {
            escalaAtual.splice(existingIndexShift, 1);
        }

        const existingIndex = escalaAtual.findIndex(
            (shift) => shift.vacancy_id === data.vacancy_id && shift.shift_id === data.shift_id
        );

        if (existingIndex !== -1) {
            escalaAtual.splice(existingIndex, 1);
        } else {
            escalaAtual.push(data)
        }

        setSalvarEscala(escalaAtual);
    }

    return (
        <>
            {isLoading &&
                <Loader size="lg" speed="fast" center content="Carregando dados..." vertical />
            }
            <>
                <Panel bordered>
                    <Form onSubmit={onSubmit} fluid formValue={formFields}
                        onChange={(formValue) => { localStorage.setItem('enfermeiro', formValue.enfermeiro); setFormFields(formValue); }} formDefaultValue={{ data_escala: getDateNow() }}>
                        <FlexboxGrid justify="center" >
                            <FlexboxGrid.Item
                                style={{
                                    padding: "10px"
                                }}
                                as={Col} colspan={24} sm={16} lg={8}
                            >

                                <Form.Group controlId="data_escala">
                                    <Form.ControlLabel>Selecione a Data da Escala:</Form.ControlLabel>
                                    <Form.Control
                                        name="data_escala"
                                        onChange={() => { setEscala() }}
                                        placeholder="Data da Escala"
                                        type="date"
                                    />
                                    <Form.HelpText>Data da Escala é obrigatório</Form.HelpText>
                                </Form.Group>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item
                                style={{
                                    padding: "10px"
                                }}
                                as={Col} colspan={24} sm={8} lg={4}
                            >
                                <ButtonToolbar style={{ marginTop: "25px" }}>
                                    <Button type="submit" appearance="primary" style={{ backgroundColor: 'rgb(10, 64, 88)' }} >Pesquisar</Button>
                                </ButtonToolbar>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                        <FlexboxGrid justify="center" >
                            <FlexboxGrid.Item
                                style={{
                                    padding: "10px"
                                }}
                                as={Col} colspan={24} sm={16} lg={8}
                            >
                                <Form.Group controlId="enfermeiro">
                                    <Form.ControlLabel>Informe o nome do(a) enfermeiro(a):</Form.ControlLabel>
                                    <Form.Control
                                        name="enfermeiro"
                                        placeholder="Informe o nome do(a) enfermeiro(a):"
                                        type="text"
                                    />
                                    <Form.HelpText>O nome do(a) enfermeiro(a) é obrigatório</Form.HelpText>
                                </Form.Group>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item
                                style={{
                                    padding: "10px"
                                }}
                                as={Col} colspan={24} sm={8} lg={4}
                            >
                                <ButtonToolbar style={{ marginTop: "25px" }}>
                                    <Button appearance="primary" style={{ backgroundColor: 'rgb(10, 64, 88)' }} onClick={save}>Salvar Escala</Button>
                                </ButtonToolbar>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Form>
                </Panel>
                <FlexboxGrid justify="center" >
                    <FlexboxGrid.Item
                        style={{
                            padding: "10px"
                        }}
                        colspan={24}
                    >
                        {escala && escala.data &&
                            <TabelaEscala handleChange={escalaChange} escala={escala} selecionados={salvarEscala} />
                        }
                    </FlexboxGrid.Item>

                </FlexboxGrid>
            </>
        </>
    );
}

export default EscalaDiaria;
