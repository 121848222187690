import React, { useEffect } from 'react';
import { Button, Checkbox, Table, Tooltip, Whisper } from 'rsuite';
import randomColor from 'randomcolor';

const { Column, HeaderCell, Cell } = Table;

function TabelaEscala(props) {
    const [data, setData] = React.useState(props.escala.data);
    const [selecionados, setSelecionados] = React.useState(props.selecionados);
    const [coresSelecionadas, setCoresSelecionadas] = React.useState(() => {
        const cores = {};
        props.escala.data.forEach(row => {
            props.escala.headers.forEach(header => {
                const key = row[`shift_${header.value}`];
                if (key && !cores[key]) {
                    cores[key] = randomColor();
                }
            });
        });
        return cores;
    });

    useEffect(() => {
        setData(props.escala.data);
    }, [props.escala.data]);

    useEffect(() => {
        setSelecionados(props.selecionados);
    }, [props.selecionados]);

    const handleChange = (key, vacancy_id) => {
        props.handleChange({ shift_id: key, vacancy_id });
    };

    const CustomComponent = ({ placement }) => {
        const color = coresSelecionadas[placement];
        return (
            <Whisper
                trigger="click"
                placement={'top'}
                controlId={`control-id-${placement}`}
                speaker={<Tooltip>{placement}</Tooltip>}
            >
                <Button style={{ width: '100%', display: 'block', textOverflow: 'ellipsis', backgroundColor: color }} size='xs' appearance="primary">{placement}</Button>
            </Whisper>
        );
    };

    const EditableCell = ({ rowData, dataKey, onChange, ...props }) => {
        const existingIndex = selecionados?.findIndex(
            (shift) => shift.vacancy_id === rowData.vacancy_id && shift.shift_id === dataKey
        );

        return (
            <Cell {...props} style={{ padding: 1 }} onClick={() => rowData[dataKey] ? {} : onChange(dataKey, rowData.vacancy_id)} >
                {rowData[dataKey] ?
                    (<CustomComponent placement={rowData[dataKey]} />) :
                    (<Checkbox color="green" checked={existingIndex !== -1} >
                    </Checkbox>)
                }
            </Cell>
        );
    };

    return <>
        <Table
            bordered={true}
            cellBordered={true}
            headerHeight={30}
            rowHeight={40}
            height={500}
            data={data}>
            {props.escala.headers.map((header) => {
                return <Column key={header.value} align="center" resizable flexGrow={1} >
                    <HeaderCell><span dangerouslySetInnerHTML={{ __html: header.label }}></span></HeaderCell>
                    <EditableCell dataKey={'shift_' + header.value} onChange={handleChange} />
                </Column>
            })}
        </Table>
    </>;
}

export default TabelaEscala;
